/* eslint-disable no-console */
import { List, Space, Typography } from 'antd'
import icons from 'assets/icons'
import { useCaseQuery } from 'features/cases'
import { useCaseManagementDoctorsQuery } from 'features/cases-management/api/query'
import { sortDoctors } from 'features/cases-management/lib/helpers'
import { EAllCasesTabType, EDistributionTabType, EMyCasesTabType } from 'features/cases-management/types/ECaseTableType'
import { useCaseManagementTabContext } from 'features/cases-management/ui/CaseManagementTabContext'
import { ROW_HEIGHT } from 'features/cases-management/ui/cases-table/CasesTable'
import { DoctorNameItem } from 'features/cases-management/ui/doctor-list/DoctorListItem'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { EmptyElement } from 'shared/ui/kit'
import styled from 'styled-components'
import { IDoctor } from 'types/IUser'
import useDeepCompareEffect from 'use-deep-compare-effect'

const { Title } = Typography

/** Количество состояний иконок стрелок сортера */
const SORTER_STATES_QUANTITY = 3

/** Cостояния иконок стрелок сортера */
export enum SorterState {
  Initial,
  Ascending,
  Descending,
}

const CustomSorter: FC<{ sorterState: number; handleSorterClick: () => void }> = ({ sorterState }) => (
  <SorterWrapper>
    {sorterState === SorterState.Initial && (
      <>
        <StyledIconNumberUp />
        <StyledIconNumberDown />
      </>
    )}
    {sorterState === SorterState.Ascending && (
      <>
        <StyledIconNumberUpFilled />
        <StyledIconNumberDown />
      </>
    )}
    {sorterState === SorterState.Descending && (
      <>
        <StyledIconNumberUp />
        <StyledIconNumberDownFilled />
      </>
    )}
  </SorterWrapper>
)

type Props = {
  /** Активная вкладка */
  currentTab: EDistributionTabType | EMyCasesTabType | EAllCasesTabType
  isFiltersPicked?: boolean
}

export const DoctorsList: FC<Props> = ({ currentTab }) => {
  const workspaceId = useCurrentWorkspaceId()
  const { data, isLoading } = useCaseManagementDoctorsQuery(Number(workspaceId))
  const doctors = data?.content || []
  const { selectedCases, setSelectedCases, setSelectedRowKeys } = useCaseManagementTabContext()
  /** Текущие врачи */
  const [currentGroup, setCurrentGroup] = useState<IDoctor[]>([])
  /** Врачи, доступные для распределения */
  const [assignableGroup, setAssignableGroup] = useState<IDoctor[]>(doctors)
  /** Стейт направления сортировки докторов */
  const [sorterStateDoctor, setSorterStateDoctor] = useState(SorterState.Initial)
  /** Выбранный доктор  */
  const [activeItem, setActiveItem] = useState<number | null>(null)
  const { search } = useLocation()
  /** Параметры поиска кейса в url*/
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  /** CaseId из url*/
  const selectedCaseId = searchParams.get('caseId')
  const { data: selectedCaseRecord } = useCaseQuery({ caseId: Number(selectedCaseId), source: 'PLATFORM' })
  // TODO изменено для хотфикса 8389
  const allSelectedCases = selectedCases
  const { t } = useTranslation()

  const handleSorterClickDoctor = () => {
    setSorterStateDoctor((prevSorterState) => (prevSorterState + 1) % SORTER_STATES_QUANTITY)
  }

  const handleNameClick = (userId: number) => setActiveItem(userId === activeItem ? null : userId)

  useEffect(() => {
    setActiveItem(null)
    setSelectedCases([])
    setSelectedRowKeys([])
  }, [currentTab])

  useDeepCompareEffect(() => {
    setAssignableGroup(doctors)
  }, [doctors])

  useDeepCompareEffect(() => {
    const currentDoctorsGroup: IDoctor[] = []
    const assignableDoctorsGroup: IDoctor[] = []

    const doctorIds = allSelectedCases.reduce((acc, currentCase) => {
      const currentDoctor = currentCase?.assignedDoctor
      if (!currentDoctor) return acc

      acc[currentDoctor.userId.toString()] = true
      return acc
    }, {} as Record<string, boolean>)

    const sortedDoctorList = sortDoctors(doctors, sorterStateDoctor)
    sortedDoctorList.forEach((doctor) => {
      const currentDoctorId = doctor.userId.toString()
      currentDoctorId in doctorIds ? currentDoctorsGroup.push(doctor) : assignableDoctorsGroup.push(doctor)
    })

    setAssignableGroup(assignableDoctorsGroup)
    setCurrentGroup(currentDoctorsGroup)
  }, [allSelectedCases, sorterStateDoctor])

  return (
    <ListsWrapper>
      <StyledHeaderSpace size={'large'}>
        <HeaderWrapper onClick={handleSorterClickDoctor}>
          <Title level={2}>{t('Врач')}</Title>
          <CustomSorter sorterState={sorterStateDoctor} handleSorterClick={handleSorterClickDoctor} />
        </HeaderWrapper>
        {/* В первой итерации нет сортировки по загрузкам */}
        {/*<HeaderWrapper>*/}
        {/*  <Title level={2}>{t('Загрузка')}</Title>*/}
        {/*  <CustomSorter sorterState={sorterStateCasesCount} handleSorterClick={handleSorterClickCasesCount} />*/}
        {/*</HeaderWrapper>*/}
      </StyledHeaderSpace>

      <ListContent>
        {currentGroup.length ? (
          <List
            header={<StyledGroupTitle level={3}>{t('Текущие врачи')}</StyledGroupTitle>}
            dataSource={currentGroup}
            renderItem={(doctor) => (
              <DoctorNameItem
                isActive={doctor.userId === activeItem}
                doctor={doctor}
                handleNameClick={() => handleNameClick(doctor.userId)}
              />
            )}
            locale={{ emptyText: '' }}
          />
        ) : (
          ''
        )}

        <List
          header={currentGroup.length ? <StyledGroupTitle level={3}>{t('Можно распределить')}</StyledGroupTitle> : ''}
          dataSource={assignableGroup}
          renderItem={(doctor) => (
            <DoctorNameItem
              isActive={doctor.userId === activeItem}
              handleNameClick={() => handleNameClick(doctor.userId)}
              doctor={doctor}
            />
          )}
          loading={isLoading}
          locale={{ emptyText: <EmptyElement /> }}
          style={{ borderTop: currentGroup.length && '1px solid var(--color-border-1)' }}
        />
      </ListContent>
    </ListsWrapper>
  )
}

const ListsWrapper = styled.div`
  height: calc(100% + 12px);
  width: 240px;
  min-width: 240px;
  border: 1px solid var(--color-border-1);
  border-right: none;
  border-bottom: none;

  h3.ant-typography {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: var(--color-text-3);
  }
  h2.ant-typography {
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    text-transform: uppercase;
  }

  .ant-list-split .ant-list-header {
    border-bottom: none;
  }

  .ant-list-header {
    padding: 0;

    h3 {
      margin-bottom: 1px;
    }
  }
`

const ListContent = styled.div`
  overflow-y: auto;
  height: calc(100% - ${ROW_HEIGHT}px);
`

const StyledGroupTitle = styled(Title)`
  padding: 8px 0 8px 16px;
`

const StyledHeaderSpace = styled(Space)`
  padding: 12px 16px;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-border-1);
  cursor: pointer;
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`

const SorterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 19px;
  width: 16px;
`
const StyledIconNumberUp = styled(icons.numberUp)`
  path {
    fill: var(--color-border-1);
  }
`
const StyledIconNumberDown = styled(icons.numberDown)`
  path {
    fill: var(--color-border-1);
  }
`
const StyledIconNumberUpFilled = styled(icons.numberUpFilled)`
  path {
    fill: var(--color-text-1);
  }
`
const StyledIconNumberDownFilled = styled(icons.numberDownFilled)`
  path {
    fill: var(--color-text-1);
  }
`
